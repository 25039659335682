import React from "react"

import Layout from "../../../components/Layout/layout"
import SEO from "../../../components/SEO/seo"
import HeroInner from "../../../components/HeroInner/hero-inner"
import ArticleFullWidth from "../../../components/ArticleFullWidth/article-full-width"
import Gallery from "../../../components/Gallery/gallery"

const ToysAndGames = () => (
  <Layout>
    <SEO title="Toys And Games" />
    <HeroInner title="Toys And Games" imageAlt="Toys And Games" imagePath="services/retail-products/toys-and-games/hero.jpg" />
    <ArticleFullWidth
      descriptions={[
        `Every Seller (Offline or e-Commerce) of Toys & Games knows the hassles of retail all too well — especially the inconvenience of sourcing items from multiple vendors. Save time and avoid lost sales by consolidating multiple vendors into one reliable partner (that’s us!). Blend-Divine carries 7,000 products and represents 300 vendors. With over 10 years of experience, Blend-Divine is entrusted by hospital gift shops, hotel gift shops, casino gift shops, boutiques, pharmacy front-ends, Amazon resellers, and other specialty stores.`,
        `We offer the following varieties in Toys & Games:`
      ]}
    />

    <Gallery
      items={[
        {
          name: "Puzzle Books",
          imagePath: "services/retail-products/toys-and-games/puzzle-books.jpg",
        },
        {
          name: "Crayons & Markers",
          imagePath: "services/retail-products/toys-and-games/crayons-and-markers.jpg",
        },
        {
          name: "Animal toys",
          imagePath: "services/retail-products/toys-and-games/animal-toys.jpg",
        },
        {
          name: "Activity Sets",
          imagePath: "services/retail-products/toys-and-games/activity-sets.jpg",
        },
        {
          name: "Slime and Ooze",
          imagePath: "services/retail-products/toys-and-games/slime.jpg",
        },
        {
          name: "Toy Cars & Vehicle",
          imagePath: "services/retail-products/toys-and-games/toy-cars.jpg",
        },
        {
          name: "Boardgame",
          imagePath: "services/retail-products/toys-and-games/board-games.jpg",
        },
      ]}
    />
  </Layout>
)

export default ToysAndGames
